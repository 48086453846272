import React, { useRef } from "react";
import "./page_editor_media.css";
import { IoMdClose } from "react-icons/io";
import { GoPlus } from "react-icons/go";

function PageEditorMedia({ setIsMedia }) {
  const mediaInputRef = useRef();

  const handleUploadMediaClick = () => {
    if (mediaInputRef.current) {
      mediaInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        console.log(file.size);
      if (file.size > 1 * 1024 ) {
        alert("File size exceeds 1 MB limit");
        return;
      }
      // Handle the selected file
      console.log(file);
    }
  };

  return (
    <section className="media-popup">
      <div className="media-popup-container">
        <div>
          <IoMdClose
            className="media-popup-icon"
            onClick={() => setIsMedia(false)}
          />
        </div>
        <h2 className="finishes-types-popup-title">Your Media</h2>

        <div className="media-content">
          <article
            className="media-item upload-media"
            onClick={handleUploadMediaClick}
          >
            <input
              type="file"
              ref={mediaInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/jpeg, image/png, image/jpg"
            />
            <div className="add-new" aria-label="Add New Quotation">
              <GoPlus className="add-new-media-icon" />
            </div>
            <p>Upload Here</p>
          </article>

          <figure className="media-item">
            <img
              src="https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://i.pinimg.com/736x/33/e5/db/33e5dbd84db1702df446b195ce049218.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://www.shutterstock.com/image-photo/green-nature-landscape-260nw-66276439.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://www.shutterstock.com/image-photo/green-nature-landscape-260nw-66276439.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://i.pinimg.com/736x/33/e5/db/33e5dbd84db1702df446b195ce049218.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://www.shutterstock.com/image-photo/green-nature-landscape-260nw-66276439.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://www.shutterstock.com/image-photo/green-nature-landscape-260nw-66276439.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://i.pinimg.com/736x/33/e5/db/33e5dbd84db1702df446b195ce049218.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://www.shutterstock.com/image-photo/green-nature-landscape-260nw-66276439.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://i.pinimg.com/736x/33/e5/db/33e5dbd84db1702df446b195ce049218.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://www.shutterstock.com/image-photo/green-nature-landscape-260nw-66276439.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>

          <figure className="media-item">
            <img
              src="https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg"
              alt="Media images"
              className="media-image"
            />
          </figure>
        </div>
      </div>
    </section>
  );
}

export default PageEditorMedia;
