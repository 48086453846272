import BookService from "../pages/book-a-service/book-a-service";
import CancellationRefund from "../pages/cancellationRefund/cancellationRefund";
import ContactUs from "../pages/contactUs/contactUs";
import LandingPage from "../pages/landingPage/landingPage";
import MyProfile from "../pages/myProfile/myProfile";
import NewQuotation from "../pages/quotations/new-quotation/new-quotation";
import NewProject from "../pages/newProject/newProject";
import PartnerSignup from "../pages/partnerSignup/partner";
import Payment from "../pages/payment-gateway/payment";
import Pricing from "../pages/pricing/pricing";
import PrivacyPolicy from "../pages/privacyPolicy/privacyPolicy";
import FinalQuotation from "../pages/quotations/final-quotation/final-quotation";
import QuotationTemplate from "../pages/quotationTemplate/quotationTemplate";
import ShippingDeliveryTerms from "../pages/shippingDeliveryTerms/shippingTerms";
import TermsAndConditions from "../pages/termsAndCondition/termsCondition";
import MyQuotation from "../pages/quotations/my-quotation/my-quotation";
import DownloadQuotation from "../pages/quotations/download-quotation/download-quotation";
import FinalInterioverseQuotation from "../pages/quotations/final-quotation/final-interioverse-quotation";
import UnityLandingPage from "../unity/page/unity-landing-page/unity-landing-page";
import SampleUnityProject from "../unity/page/unity_create_project/unity_create_project";

// export const ROUTES = [
//     {
//       path: '/',
//       element: <LandingPage/>
//     },
//     {
//       path: '/pricing',
//       element: <Pricing />
//     },
//     {
//       path: '/new-project',
//       element: <NewProject />
//     },
//     {
//       path: '/privacy-policy',
//       element: <Privacy_Policy />
//     },
//     {
//       path: '/shipping-delivery',
//       element: <Shipping_delivery_terms />
//     },
//     {
//       path: '/cancellation-refund',
//       element: <Cancellation_refund/>
//     },
//     {
//       path: '/terms-conditions',
//       element: <Terms_and_conditions/>
//     },
//     {
//       path: '/contact-us',
//       element: <Contact_Us/>
//     },
//     {
//       path: '/be-a-partner',
//       element: <Partner_signup/>
//     },
//     {
//       path: '/quotation-template',
//       element: <Quotation_Template/>
//     },
//     {
//       path: '/editor',
//       element: <Editor/>
//     },
//     {
//       path:'/new-quotation',
//       element: <NewQuotation />
//     },
//     {
//       path:'/my-profile',
//       element: <MyProfile />
//     },
//     {
//       path:'/my-brand',
//       element: <MyProfile />
//     },
//     {
//       path:'/my-orders',
//       element: <MyProfile />
//     },
//     {
//       path:'/book-service',
//       element: <BookService />
//     },
//     {
//       path:'/payment',
//       element: <Payment />
//     },
//   ];

export const publicRoutes = [
  { path: "/", element: <LandingPage /> },
  { path: "/pricing", element: <Pricing /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/shipping-delivery", element: <ShippingDeliveryTerms /> },
  { path: "/cancellation-refund", element: <CancellationRefund /> },
  { path: "/terms-conditions", element: <TermsAndConditions /> },
  { path: "/contact-us", element: <ContactUs /> },
  { path: "/final-quotation/:projectId/:version", element: <FinalQuotation /> },
  {
    path: "/final/interioverse-quotation/:projectId/:version",
    element: <FinalInterioverseQuotation />,
  },
];

export const privateRoutes = [
  //adminRole === "Designer"
  { path: "/new-project", element: <NewProject /> },
  //adminRole === "Designer"
  { path: "/quotation-template", element: <QuotationTemplate /> },

  { path: "/be-a-partner", element: <PartnerSignup /> },

  //adminRole === "Designer"
  { path: "/new-project/:projectId/:version", element: <NewQuotation /> },
  { path: "/my-project/:projectId/:version", element: <NewQuotation /> },
  { path: "/my-quotation/:projectId/:version", element: <MyQuotation /> },
  { path: "/interioverse-quotation/:projectId/:version", element: <MyQuotation /> },
  { path: "/download-quotation/:projectId/:version", element: <DownloadQuotation /> },
  

  { path: "/my-profile", element: <MyProfile /> },
  { path: "/my-brand", element: <MyProfile /> },

  //adminRole === "Designer"
  { path: "/my-orders", element: <MyProfile /> },

  //adminRole === "Designer"
  { path: "/book-service", element: <BookService /> },

  //adminRole === "Designer"
  { path: "/payment", element: <Payment /> },

  //unity "
  { path: "/unity", element: <UnityLandingPage /> },
  { path: "/unity-create-project", element: <SampleUnityProject /> },
];
