import React, { useState } from "react";
import "./customDropDown.css";

const CustomDropdown = ({ form, handleChange, getFilteredCities }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleInputChange = (e) => {
    handleChange(e);
    setDropdownVisible(e.target.value.length > 1);
  };

  const handleOptionSelect = (city) => {
    handleChange({ target: { name: "project_location", value: city } });
    setDropdownVisible(false);
  };

  const filteredCities = getFilteredCities();

  return (
    <div
      className={`dropdown-container ${
        form.project_location === "Other"
          ? "new-project-dropdown-container"
          : ""
      }`}
    >
      <input
        id="project-location"
        type="text"
        className="project-form-input"
        placeholder="Enter your project location"
        required
        name="project_location"
        value={form.project_location}
        onChange={handleInputChange}
        autoComplete="off"
      />
      {isDropdownVisible && (
        <ul className="dropdown-list">
          {filteredCities.length > 0 ? (
            filteredCities.map((city, index) => (
              <li
                key={index}
                className="dropdown-item"
                onClick={() => handleOptionSelect(city)}
              >
                {city}
              </li>
            ))
          ) : (
            <li
              className="dropdown-item"
              onClick={() => handleOptionSelect("Other")}
            >
              Other
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
