import React, { useEffect, useState } from "react";
import landingPageImg from "../../assets/Landing_page_picture.jpg";
import "./newProject.css";
import CustomRadioButton from "../../components/radioBtn/radioBtn";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import AXIOS from "../../services/axios";
import CustomDropdown from "../../components/customDropDown/customDropDown";

function NewProject() {
  const [form, setForm] = useState({
    project_name: "",
    project_layout: "",
    project_location: "",
    client_location: "",
    property_name: "",
    builder_name: "",
  });
  const [cities, setCities] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await AXIOS.get("/all-cities-list");
        if (response.status === 200) {
          setCities(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCities();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (form.project_location === "Other" && form.client_location) {
        form.project_location = form.client_location;
      }

      if (form.project_location === "Bengaluru/Bangalore") {
        form.project_location = "Bengaluru";
      }

      const response = await AXIOS.post("/project/create-project", form);
      if (response.status === 200) {
        navigate(`/new-project/${response.data.project.project_id}/Version 1`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  // Function to get filtered city list
  const getFilteredCities = () => {
    if (form.project_location.length > 1) {
      return cities
        .filter((city) =>
          city.toLowerCase().includes(form.project_location.toLowerCase())
        )
        .slice(0, 5);
    }
    return [];
  };

  return (
    <section style={{ margin: "0px", padding: "0" }}>
      <div className="new-project">
        <img src={landingPageImg} alt="" className="cover-img" />

        <div className="new-project-container">
          <IoMdClose
            className="new-project-close-icon"
            onClick={() => navigate("/")}
          />
          <div className="new-project-content">
            <h1 className="new-project-heading">Create New Project</h1>
            <form onSubmit={handleSubmit}>
              <div className="project-form">
                <div className="project-form-section">
                  <div className="project-form-group">
                    <label
                      className="project-form-label"
                      htmlFor="project-name"
                    >
                      Project Name
                    </label>
                    <input
                      id="project-name"
                      type="text"
                      className="project-form-input"
                      placeholder="Name your project"
                      required
                      name="project_name"
                      value={form.project_name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="project-form-group">
                    <label
                      className="project-form-label"
                      htmlFor="project-layout"
                    >
                      Project Layout
                    </label>
                    <input
                      id="project-layout"
                      type="text"
                      className="project-form-input"
                      placeholder="Select Your Layout"
                      value={form.project_layout}
                      name="project_layout"
                      readOnly
                      required
                    />

                    <div className="project-layout-options">
                      <div className="layout-options-row">
                        <div className="layout-option">
                          <CustomRadioButton
                            id="studio"
                            name="project_layout"
                            checked={form.project_layout === "Studio"}
                            onChange={handleChange}
                            value="Studio"
                          />
                          <p>Studio</p>
                        </div>
                        <div className="layout-option">
                          <CustomRadioButton
                            id="2bhk"
                            name="project_layout"
                            value="2 BHK"
                            checked={form.project_layout === "2 BHK"}
                            onChange={handleChange}
                          />
                          <p>2 BHK</p>
                        </div>
                        <div className="layout-option">
                          <CustomRadioButton
                            id="3bhk"
                            name="project_layout"
                            value="3 BHK"
                            checked={form.project_layout === "3 BHK"}
                            onChange={handleChange}
                          />
                          <p>3 BHK</p>
                        </div>
                        <div className="layout-option">
                          <CustomRadioButton
                            id="4bhk"
                            name="project_layout"
                            value="4 BHK"
                            checked={form.project_layout === "4 BHK"}
                            onChange={handleChange}
                          />
                          <p>4 BHK</p>
                        </div>
                      </div>
                      <div className="layout-options-row">
                        <div className="layout-option">
                          <CustomRadioButton
                            id="villa"
                            name="project_layout"
                            value="Villa"
                            checked={form.project_layout === "Villa"}
                            onChange={handleChange}
                          />
                          <p>Villa</p>
                        </div>
                        <div className="layout-option">
                          <CustomRadioButton
                            id="office"
                            name="project_layout"
                            value="Office"
                            checked={form.project_layout === "Office"}
                            onChange={handleChange}
                          />
                          <p>Office</p>
                        </div>
                        <div className="layout-option">
                          <CustomRadioButton
                            id="showroom"
                            name="project_layout"
                            value="Showroom"
                            checked={form.project_layout === "Showroom"}
                            onChange={handleChange}
                          />
                          <p>Showroom</p>
                        </div>
                        <div className="layout-option">
                          <CustomRadioButton
                            id="others"
                            name="project_layout"
                            value="Others"
                            checked={form.project_layout === "Others"}
                            onChange={handleChange}
                          />
                          <p>Others</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="project-form-section">
                  <div className="project-form-group">
                    <label
                      className="project-form-label"
                      htmlFor="project-location"
                    >
                      Project Location
                    </label>
                    <div className="project-location-input">
                      <CustomDropdown
                        form={form}
                        handleChange={handleChange}
                        getFilteredCities={getFilteredCities}
                      />

                      {form.project_location === "Other" && (
                        <input
                          id="client-location"
                          type="text"
                          className="project-form-input"
                          placeholder="Enter your project location"
                          required
                          name="client_location"
                          value={form.client_location}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </div>

                  <div className="project-form-group">
                    <label
                      className="project-form-label"
                      htmlFor="property-name"
                    >
                      Name of Property
                    </label>
                    <input
                      id="property-name"
                      type="text"
                      className="project-form-input"
                      placeholder="Enter your property name"
                      required
                      name="property_name"
                      value={form.property_name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="project-form-group">
                    <label
                      className="project-form-label"
                      htmlFor="builder-name"
                    >
                      Name of Builder
                    </label>
                    <input
                      id="builder-name"
                      type="text"
                      className="project-form-input"
                      placeholder="Enter your builder name"
                      required
                      name="builder_name"
                      value={form.builder_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="create-project-btn">
                <button type="submit">Create Project</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewProject;
