import React, { useEffect, useRef, useState } from "react";
import landingPageImg from "../../../assets/Landing_page_picture.jpg";
import "./final-quotation.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AXIOS from "../../../services/axios";
import {
  extractCategoryInfo,
  generateTable,
  intialRateTable,
} from "../../../services/reusableCodes";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../../components/loading/loading";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import CustomTextBox from "../../../components/customizedQuilTexBox/CustomTextBox";
import { MdOutlineTextFields } from "react-icons/md";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import FinalQuotationTable from "./final-quotation-table";
import { useSelector } from "react-redux";
import MobileScreenMessage from "../../../components/mobileScreenMessage/mobileScreenMessage";

function FinalQuotation() {
  const [color, setColor] = useState({
    background_color: "#fff7ef",
    border_color: "#fff7ef",
    table_header: "#f16f40",
    table_font: "#ffffff",
    table_border: "#f16f40",
    table_amount: "#ffffff",
    amount_font: "#636363",
  });
  const [selectedFontFamily, setSelectedFontFamily] = useState("Montserrat");
  const { pathname } = useLocation();
  const { projectId,version } = useParams();
  const initialTable = generateTable();
  const initialRateTable = intialRateTable(initialTable);
  const [areaTables, setAreaTables] = useState([initialTable]);
  const [subTotalTables, setSubTotalTables] = useState({
    subTotal: "0",
    total: "0",
    taxableAmount: "0",
    gst: {
      name: "",
      percentage: "",
      amount: "0",
    },
    add: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
    minus: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
  });
  const [loading, setLoading] = useState(true);
  const [editorPages, setEditorPages] = useState();
  const [tablePages, setTablePages] = useState([]);
  const [initialPages, setInitialPages] = useState([]);
  const [initialBoxes, setInitialBoxes] = useState([]);
  const [pageContentData, setPageContentData] = useState({
    content: [],
    pageMerge: "",
  });
  const [isGenerating, setIsGenerating] = useState(false);
  const isMobile= useSelector((state) => state.isMobileScreen.isMobile);
  const [isAccessible,setIsAccesible] = useState(false)

  useEffect(() => {
    const handleGetTableData = async (version) => {
      try {
        let response;
  
        response = await AXIOS.get(`/user-template/final/template/${projectId}/${version}`);
        if (response && response.status === 200) {
          let templateData;
          let pagesContent;
          let pageToMerge;
          let color;
          
  
          if (response.data.user_template) {
            templateData = response.data.user_template;
            pagesContent = response.data.pagesContent;
            pageToMerge = parseInt(response.data.pageToMerge, 10);
            color = response.data.color;
          } else {
            templateData = response.data.baseTemplates[0];
            pagesContent = response.data.baseTemplates[0].pagesContent;
            pageToMerge = parseInt(
              response.data.baseTemplates[0].pageToMerge,
              10
            );
          }
  
          
  
          if (pagesContent) {
            let newEditorPages = pagesContent
              .slice(0, pageToMerge)
              .map((page) => ({
                id: page.id,
                editorRef: React.createRef(),
                quillRef: React.createRef(),
                textBox: page.textbox.map((box) => ({
                  id: box.id,
                  textBoxRef: React.createRef(),
                  quillRef: React.createRef(),
                  position: JSON.parse(box.position),
                  dragging: { current: JSON.parse(box.dragging) },
                  offset: { current: JSON.parse(box.offset) },
                  resize: box.resize,
                  image: JSON.parse(box.image),
                  width: box.width,
                  height: box.height,
                  zIndex: JSON.parse(box.zIndex),
                  fontSize: box.fontSize,
                  content: "",
                  isTextareaActive: false,
                })),
              }));
  
            let newTablePages = pagesContent.slice(pageToMerge).map((page) => ({
              id: page.id,
              editorRef: React.createRef(),
              quillRef: React.createRef(),
              textBox: page.textbox.map((box) => ({
                id: box.id,
                textBoxRef: React.createRef(),
                quillRef: React.createRef(),
                position: JSON.parse(box.position),
                dragging: { current: JSON.parse(box.dragging) },
                offset: { current: JSON.parse(box.offset) },
                resize: box.resize,
                image: JSON.parse(box.image),
                width: box.width,
                height: box.height,
                zIndex: JSON.parse(box.zIndex),
                fontSize: box.fontSize,
                content: "",
                isTextareaActive: false,
              })),
            }));
  
  
  
            if (newEditorPages.length > 0) {
              setEditorPages(newEditorPages);
            }
  
            if (newTablePages.length > 0) {
              setTablePages(newTablePages);
            }
            setInitialPages(newEditorPages.concat(newTablePages));
  
            let boxes = [];
            newEditorPages.concat(newTablePages).map((page) => {
              page.textBox.map((box) => {
                boxes.push(box);
              });
            });
  
            setInitialBoxes(boxes);
            setPageContentData((prev) => ({
              ...prev,
              content: pagesContent,
              pageMerge: pageToMerge,
            }));
          }
  
          if (templateData) {
            let newSubTotalTables;
  
            newSubTotalTables = {
              subTotal: templateData.user_subTotal || templateData.subTotal,
              total: templateData.user_total || templateData.total,
              taxableAmount:
                templateData.user_taxableAmount || templateData.taxableAmount,
              gst: templateData.user_gst || templateData.gst,
              add: templateData.user_add || templateData.add,
              minus: templateData.user_minus || templateData.minus,
            };
  
            // Extract areaTables data from templateData
            const newAreaTables = (templateData.template || []).map(
              (table) => ({
                id: table.id || uuidv4(),
                area: (table.areas || []).map((area) => ({
                  id: area.area_id || uuidv4(),
                  areaValue: area.name || "",
                  amount: area.amount || "",
                  items: (area.items || []).map((item) => ({
                    id: item.item_id || uuidv4(),
                    itemValue: item.name || "",
                    descriptionValue: item.description || "",
                    amount: item.amount || "",
                    subItems: (item.subItems || []).map((subItem) => ({
                      id: subItem.name_id || uuidv4(),
                      subItemValue: subItem.name || "",
                      amount: subItem.amount || "",
                      category: subItem.category || "",
                      finish: subItem.finish || "",
                      type: subItem.type || "",
                      height: subItem.height || "",
                      width: subItem.width || "",
                      amount: subItem.amount || "",
                      unit: subItem.unit || "",
                      range: subItem.range || "",
                      rate: subItem.rate || "",
                    })),
                  })),
                })),
              })
            );
            setColor({
              background_color:
                templateData.background_color ||
                color.background_color ||
                "#fff7ef",
              table_header:
                templateData.table_header || color.table_header || "#f16f40",
              table_font:
                templateData.table_font || color.table_font || "#ffffff",
              table_border:
                templateData.table_border || color.table_border || "#f16f40",
              table_amount:
                templateData.table_amount || color.table_amount || "#ffffff",
              amount_font:
                templateData.amount_font || color.amount_font || "#636363",
            });
            setSelectedFontFamily(
              templateData.font_family || color.font_family || "Montserrat"
            );
            // Update the states
            setSubTotalTables(newSubTotalTables);
            setAreaTables(newAreaTables);
            setLoading(false);
          }
        }
      } catch (error) {
        if (error.response.status===402) {
          setIsAccesible(true)
        }
        setLoading(false);
      }
    };

    if (!isMobile) {
      handleGetTableData(version);
    }else{
      setLoading(false)
    }
  }, []);
  

  useEffect(() => {
    if (initialPages) {
      multiInitializeEditors(initialPages);
    }
    if (initialBoxes) {
      multiInitializeTextBox(initialBoxes);
    }
  }, [initialPages, initialBoxes]);

  const multiInitializeEditors = (pages) => {
    pages.forEach((page) => {
      if (page.editorRef.current && !page.quillRef.current) {
        const quill = new Quill(page.editorRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `#toolbar-${page.id}`,
              handlers: {
                customTextBox: () =>
                  quill.getModule("customTextBox").showTextBox(),
              },
            },
            customTextBox: {
              showTextBox: (range) => {
                const bounds = quill.getBounds(range);
              },
            },
          },
        });

        page.editorRef.current.setAttribute("spellinitialPages", "false");
        page.quillRef.current = quill;
      }
    });
  };

  const multiInitializeTextBox = (boxes) => {
    boxes.forEach((box) => {
      if (box.textBoxRef.current && !box.quillRef.current) {
        const Font = Quill.import("formats/font");
        Font.whitelist = ["mirza", "roboto", "serif"];
        Quill.register(Font, true);

        const quill = new Quill(box.textBoxRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `#textBox-${box.id}`,
            },
          },
        });

        box.textBoxRef.current.setAttribute("spellinitialPages", "false");
        box.quillRef.current = quill;
      }
    });
  };

  useEffect(() => {
    const tableData = async () => {
      try {
        if (
          pageContentData.content &&
          Array.isArray(pageContentData.content) &&
          pageContentData.content.length > 0
        ) {
          const pagesContent = pageContentData.content;
          const pageToMerge = parseInt(pageContentData.pageMerge, 10);

          // Update editorPages
          editorPages.forEach((prevPage) => {
            const matchedPage = pagesContent
              .slice(0, pageToMerge)
              .find((page) => page.id === prevPage.id);
            if (matchedPage) {
              if (prevPage.quillRef.current) {
                prevPage.quillRef.current.root.innerHTML =
                  matchedPage.quillData;
              }

              prevPage.textBox.forEach((prevBox) => {
                const matchedBox = matchedPage.textbox.find(
                  (box) => box.id === prevBox.id
                );
                if (matchedBox) {
                  if (prevBox.quillRef.current) {
                    prevBox.quillRef.current.root.innerHTML =
                      matchedBox.quillData;
                  }
                }
              });
            }
          });

          // Update tablePages
          tablePages.forEach((prevPage) => {
            const matchedPage = pagesContent
              .slice(pageToMerge)
              .find((page) => page.id === prevPage.id);
            if (matchedPage) {
              if (prevPage.quillRef.current) {
                prevPage.quillRef.current.root.innerHTML =
                  matchedPage.quillData;
              }

              prevPage.textBox.forEach((prevBox) => {
                const matchedBox = matchedPage.textbox.find(
                  (box) => box.id === prevBox.id
                );
                if (matchedBox) {
                  if (prevBox.quillRef.current) {
                    prevBox.quillRef.current.root.innerHTML =
                      matchedBox.quillData;
                  }
                }
              });
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    tableData();
  }, [initialPages, initialBoxes]);

  function generatePDF() {
    const input = document.querySelector(".final-quotation-template-container");

    if (!input) {
      console.error("Quotation template container not found");
      return;
    }

    setIsGenerating(true);

    // Store original border style
    const editors = document.querySelectorAll(".pdf-text-editor-space");
    editors.forEach((editor) => (editor.style.border = "none"));

    html2canvas(input, {
      scale: 2, // Reducing the scale
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.95); // Using JPEG format and reducing quality
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const pageHeight = 297; // A4 page height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Restore original border style
      editors.forEach(
        (editor) => (editor.style.border = "1.5px dashed #b4b4b4")
      );

      pdf.save(`Interioverse_quotation-${projectId}.pdf`); // Change the name of the downloaded PDF
      // Increment PDF download count
     AXIOS.put('/user-template/increment-pdf-download', {
      project_id: projectId,
      download_type: 'user',
    });
      setIsGenerating(false);
    });
  }

  if (loading) {
    return <Loading />;
  }
  
  if (isMobile) {
    return(
      <MobileScreenMessage />
    )
  }

  if (isAccessible) {
    return(
      <div className="message-container">
      <p className="message-greeting">Hey There!</p>
      <p className="message-apology">Sorry, you don't have access to this page.</p>
    </div>
    )
  }

  return (
    <div>
      <figure>
        <img
          src={landingPageImg}
          alt="Cover img"
          className="partner-cover-img"
        />
      </figure>
      <div className="generate-pdf-btn">
        <button onClick={generatePDF}>{isGenerating?"Processing":"Download"}</button>
      </div>
      <section className="final-quotation-template">
        <article className="final-quotation-template-container">
          {editorPages && editorPages.length > 0 && (
            <section
              className="editor-section"
              style={{
                margin: "0px",
              }}
            >
              {editorPages.map((editor) => (
                <div key={editor.id} className="pdf-text-editor">
                  <div
                    className="pdf-text-editor-space"
                    style={{
                      backgroundColor: color.background_color,
                      marginBottom: "0",
                    }}
                  >
                    <div>
                      {editor.textBox &&
                        editor.textBox.map((box) => (
                          <div
                            className="draggable-container"
                            key={box.id}
                            style={{ height: "auto" }}
                          >
                            <div
                              style={{
                                left: box.position.x,
                                top: box.position.y,
                                width: box.width ? box.width : "",
                                height: box.height ? box.height : "",
                                resize: "none",
                                border: "none",
                                padding: "0px",
                                zIndex: `${box.zIndex ? box.zIndex : 1}`,
                                fontSize: box.fontSize
                                  ? `${box.fontSize}px`
                                  : "13px",
                              }}
                              ref={box.textBoxRef}
                              className="pdf-text-box"
                              spellinitialPages={false}
                            ></div>
                            <div
                              id={`textBox-${box.id}`}
                              className="text-box-tools-container"
                              style={{
                                display: "none",
                              }}
                            ></div>
                          </div>
                        ))}
                      <div
                        ref={editor.editorRef}
                        className="pdf-text-enter-space"
                      ></div>
                    </div>
                  </div>
                  <div className="block-to-type"></div>
                </div>
              ))}
            </section>
          )}

          <FinalQuotationTable
            color={color}
            areaTables={areaTables}
            subTotalTables={subTotalTables}
            selectedFontFamily={selectedFontFamily}
            pathname={pathname}
          />

          {tablePages && tablePages.length > 0 && (
            <section
              className="editor-section"
              style={{
                margin: "0px",
              }}
            >
              {tablePages.map((editor) => (
                <div key={editor.id} className="pdf-text-editor">
                  <div
                    className="pdf-text-editor-space"
                    style={{
                      backgroundColor: color.background_color,
                      marginBottom: "0px",
                    }}
                  >
                    <div>
                      {editor.textBox &&
                        editor.textBox.map((box) => (
                          <div
                            className="draggable-container"
                            key={box.id}
                            style={{ height: "auto" }}
                          >
                            <div
                              style={{
                                left: box.position.x,
                                top: box.position.y,
                                width: box.width ? box.width : "",
                                height: box.height ? box.height : "",
                                resize: "none",
                                border: "none",
                                padding: "0px",
                                zIndex: `${box.zIndex ? box.zIndex : 1}`,
                                fontSize: box.fontSize
                                  ? `${box.fontSize}px`
                                  : "13px",
                              }}
                              ref={box.textBoxRef}
                              className="pdf-text-box"
                              spellinitialPages={false}
                            ></div>
                            <div
                              id={`textBox-${box.id}`}
                              className="text-box-tools-container"
                              style={{
                                display: "none",
                              }}
                            ></div>
                          </div>
                        ))}
                      <div
                        ref={editor.editorRef}
                        className="pdf-text-enter-space"
                      ></div>
                    </div>
                  </div>
                  <div className="block-to-type"></div>
                </div>
              ))}
            </section>
          )}
        </article>
      </section>

      {editorPages &&
        editorPages.concat(tablePages).map((page) => (
          <div
            className="pdf-editor-text-header"
            key={page.id}
            style={{
              display: "none",
            }}
          >
            <p className="pdf-editor-text-title">Page Editor</p>
            <div id={`toolbar-${page.id}`}>
              <button className="ql-customTextBox">
                <MdOutlineTextFields />
              </button>
            </div>
          </div>
        ))}
    </div>
  );
}

export default FinalQuotation;
