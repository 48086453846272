import React, { useState, useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./quotationTemplate.css";
import { IoMdRefresh } from "react-icons/io";
import { HiOutlineMinusSm } from "react-icons/hi";
import { BiSolidColorFill } from "react-icons/bi";
import landingPageImg from "../../assets/Landing_page_picture.jpg";
// import ResizableImage from "../../components/quill-resize-image/quill-resize-image";
import QuotationTable from "../../components/quotation_table/quotation_table";
import AXIOS from "../../services/axios";
import { RiFontFamily } from "react-icons/ri";
import { ImTextWidth } from "react-icons/im";
import { v4 as uuidv4 } from "uuid";
import { generateTable, intialRateTable } from "../../services/reusableCodes";
import Loading from "../../components/loading/loading";
import PageEditorMedia from "../../components/pageEditorMedia/page_editor_media";
import CustomTextBox from "../../components/customizedQuilTexBox/CustomTextBox";
import { RiDragMoveFill } from "react-icons/ri";
import { HiMinus } from "react-icons/hi";
import { FaPlus } from "react-icons/fa6";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import useScreenWidth from "../../components/useScreenWidth";
import useScreenOrientation from "../../components/useScreenOrientation";
import MobileScreeMessage from "../../components/mobileScreenMessage/mobileScreenMessage";
import { useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";

function QuotationTemplate() {
  const [color, setColor] = useState({
    background_color: "#fff7ef",
    table_header: "#f16f40",
    table_font: "#ffffff",
    table_border: "#f16f40",
    table_amount: "#ffffff",
    amount_font: "#636363",
  });
  const [selectedFontFamily, setSelectedFontFamily] = useState("Montserrat");
  const inputRefs = {
    table_font: useRef(null),
    table_border: useRef(null),
    table_amount: useRef(null),
    amount_font: useRef(null),
    table_header: useRef(null),
    background_color: useRef(null),
  };
  const fontFamilies = [
    "Montserrat",
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Courier New",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman",
    "Comic Sans MS",
    "Trebuchet MS",
    "Tahoma",
    "Geneva",
    "Lucida Grande",
    "Calibri",
    "Century Gothic",
  ];
  const [finishes, setFinishes] = useState(false);
  const [editorPages, setEditorPages] = useState([
    {
      id: uuidv4(),
      editorRef: React.createRef(),
      quillRef: React.createRef(),
      textBox: [],
    },
  ]);
  const [tablePages, setTablePages] = useState([]);
  const initialTable = generateTable();
  const initialRateTable = intialRateTable(initialTable);
  const [areaTables, setAreaTables] = useState([initialTable]);
  const [rateTables, setRateTables] = useState([initialRateTable]);
  const [subTotalTables, setSubTotalTables] = useState({
    subTotal: "0",
    total: "0",
    taxableAmount: "0",
    gst: {
      name: "",
      percentage: "",
      amount: "0",
    },
    add: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
    minus: [{ id: uuidv4(), name: "", percentage: "", amount: "0" }],
  });
  const [activePage, setActivePage] = useState(null);
  const [activeBox, setActiveBox] = useState(null);
  const [initialPages, setInitialPages] = useState([]);
  const [initialBoxes, setInitialBoxes] = useState([]);
  const [pageContentData, setPageContentData] = useState({
    content: [],
    pageMerge: "",
  });
  const [updateSave, setUpdateSave] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMedia, setIsMedia] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isScreenSmall = useScreenWidth(1100);
  const isLandscape = useScreenOrientation();
  const isMobile = useSelector((state) => state.isMobileScreen.isMobile);

  const tableData = async (e) => {
    try {
      const response = await AXIOS.get("/user-template/base-template");
      if (response.status === 200) {
        const templateData = response.data.baseTemplates[0];

        if (templateData) {
          initializeTemplateData(
            templateData,
            setSubTotalTables,
            setAreaTables,
            setEditorPages,
            setTablePages,
            setActivePage,
            initializeEditors,
            setActiveBox,
            initializeTextBox,
            setInitialPages,
            setInitialBoxes,
            setPageContentData,
            setColor,
            setLoading
          );
        }
      }
    } catch (error) {
      setActivePage(editorPages[0]);
      initializeEditors(editorPages[0]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      tableData();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (initialPages) {
      multiInitializeEditors(initialPages);
    }
    if (initialBoxes) {
      multiInitializeTextBox(initialBoxes);
    }
  }, [initialPages, initialBoxes, activePage]);

  const multiInitializeEditors = (pages) => {
    pages.forEach((page) => {
      if (page.editorRef.current && !page.quillRef.current) {
        const Font = Quill.import("formats/font");
        Font.whitelist = ["mirza", "roboto", "serif"];
        Quill.register(Font, true);

        const Size = Quill.import("attributors/style/size");
        Size.whitelist = ["10px", "13px", "18px", "24px", "32px", "48px"];
        Quill.register(Size, true);

        const quill = new Quill(page.editorRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `#toolbar-${page.id}`,
              handlers: {
                bold: function () {
                  const isBold = quill.getSelection() && quill.getFormat().bold;
                  quill.format("bold", !isBold);
                },
                italic: function () {
                  const isItalic =
                    quill.getSelection() && quill.getFormat().italic;
                  quill.format("italic", !isItalic);
                },
                underline: function () {
                  const isUnderline =
                    quill.getSelection() && quill.getFormat().underline;
                  quill.format("underline", !isUnderline);
                },
                color: function (value) {
                  console.log(value);
                  if (quill.getSelection()) {
                    quill.format("color", value);
                    quill.format("background", false);
                  }
                },
                background: function (value) {
                  if (quill.getSelection()) {
                    quill.format("background", value);
                  }
                },
                clean: function () {
                  const selection = quill.getSelection();
                  if (selection) {
                    quill.removeFormat(selection.index, selection.length);
                  }
                },
                list: function (value) {
                  const range = quill.getSelection();
                  if (range) {
                    const format = quill.getFormat(range);
                    if (format.list === value) {
                      quill.format("list", false);
                    } else {
                      quill.format("list", value);
                    }
                  }
                },
                indent: function (value) {
                  quill.format("indent", value);
                },
                align: function (value) {
                  quill.format("align", value);
                },
                size: function (value) {
                  const range = quill.getSelection();
                  if (range) {
                    quill.format("size", value);
                  }
                },
                font: function (value) {
                  const currentSelection = quill.getSelection();
                  if (currentSelection) {
                    const { index, length } = currentSelection;
                    quill.formatText(index, length, "font", value);
                    setTimeout(() => {
                      quill.setSelection(index, length);
                    }, 0);
                  }
                },
                customTextBox: () =>
                  quill.getModule("customTextBox").showTextBox(),
              },
            },
            customTextBox: {
              showTextBox: (range) => {
                const bounds = quill.getBounds(range);
                addTextBox(page.id);
              },
            },
          },
        });

        page.editorRef.current.setAttribute("spellinitialPages", "false");
        page.quillRef.current = quill;
      }
    });
  };

  const multiInitializeTextBox = (boxes) => {
    boxes.forEach((box) => {
      if (box.textBoxRef.current && !box.quillRef.current) {
        const Font = Quill.import("formats/font");
        Font.whitelist = ["mirza", "roboto", "serif"];
        Quill.register(Font, true);

        const quill = new Quill(box.textBoxRef.current, {
          theme: "snow",
          modules: {
            toolbar: {
              container: `#textBox-${box.id}`,
              handlers: {
                bold: function () {
                  const isBold = quill.getSelection() && quill.getFormat().bold;
                  quill.format("bold", !isBold);
                },
                italic: function () {
                  const isItalic =
                    quill.getSelection() && quill.getFormat().italic;
                  quill.format("italic", !isItalic);
                },
                underline: function () {
                  const isUnderline =
                    quill.getSelection() && quill.getFormat().underline;
                  quill.format("underline", !isUnderline);
                },
                color: function (value) {
                  if (box.image) return;
                  if (quill.getSelection()) {
                    quill.format("color", value);
                    quill.format("background", false);
                  }
                },
                background: function (value) {
                  if (box.image) return;
                  if (quill.getSelection()) {
                    quill.format("background", value);
                  }
                },
                clean: function () {
                  const selection = quill.getSelection();
                  if (selection) {
                    quill.removeFormat(selection.index, selection.length);
                  }
                },
                image: function () {
                  const input = document.createElement("input");
                  input.setAttribute("type", "file");
                  input.setAttribute("accept", "image/*");

                  input.onchange = async (event) => {
                    const file = event.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        const dataUrl = e.target.result;

                        // Create an image element to get the dimensions
                        const img = new Image();
                        img.onload = () => {
                          const width = img.width;
                          const height = img.height;

                          quill.focus();
                          const range = quill.getSelection();
                          quill.setContents([]); // Clear the content
                          quill.insertEmbed(range.index, "image", dataUrl);
                          quill.insertText(range.index + 1, "\u200B"); // Insert zero-width space
                          quill.setSelection(range.index + 1); // Set cursor to the zero-width space

                          if (box.image === false) {
                            textBoxImage(box.id, width, height);
                          }
                        };
                        img.src = dataUrl;
                      };
                      reader.readAsDataURL(file);
                    }
                  };
                  input.click();
                },
                list: function (value) {
                  const range = quill.getSelection();
                  if (range) {
                    const format = quill.getFormat(range);
                    if (format.list === value) {
                      quill.format("list", false);
                    } else {
                      quill.format("list", value);
                    }
                  }
                },
                align: function (value) {
                  quill.format("align", value);
                },
                font: function (value) {
                  if (box.image) return;
                  const currentSelection = quill.getSelection();
                  if (currentSelection) {
                    const { index, length } = currentSelection;
                    quill.formatText(index, length, "font", value);
                    setTimeout(() => {
                      quill.setSelection(index, length);
                    }, 0);
                  }
                },
              },
            },
          },
        });

        box.textBoxRef.current.setAttribute("spellinitialPages", "false");
        box.quillRef.current = quill;
      }
    });
  };

  useEffect(() => {
    const tableData = async () => {
      try {
        if (
          pageContentData.content &&
          Array.isArray(pageContentData.content) &&
          pageContentData.content.length > 0
        ) {
          const pagesContent = pageContentData.content;
          const pageToMerge = parseInt(pageContentData.pageMerge, 10);

          // Update editorPages
          editorPages.forEach((prevPage) => {
            const matchedPage = pagesContent
              .slice(0, pageToMerge)
              .find((page) => page.id === prevPage.id);
            if (matchedPage) {
              if (prevPage.quillRef.current) {
                prevPage.quillRef.current.root.innerHTML =
                  matchedPage.quillData;
              }

              prevPage.textBox.forEach((prevBox) => {
                const matchedBox = matchedPage.textbox.find(
                  (box) => box.id === prevBox.id
                );
                if (matchedBox) {
                  if (prevBox.quillRef.current) {
                    prevBox.quillRef.current.root.innerHTML =
                      matchedBox.quillData;
                  }
                }
              });
            }
          });

          // Update tablePages
          tablePages.forEach((prevPage) => {
            const matchedPage = pagesContent
              .slice(pageToMerge)
              .find((page) => page.id === prevPage.id);
            if (matchedPage) {
              if (prevPage.quillRef.current) {
                prevPage.quillRef.current.root.innerHTML =
                  matchedPage.quillData;
              }

              prevPage.textBox.forEach((prevBox) => {
                const matchedBox = matchedPage.textbox.find(
                  (box) => box.id === prevBox.id
                );
                if (matchedBox) {
                  if (prevBox.quillRef.current) {
                    prevBox.quillRef.current.root.innerHTML =
                      matchedBox.quillData;
                  }
                }
              });
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    tableData();
  }, [initialPages, initialBoxes]);

  const handleDivClick = (inputName) => {
    inputRefs[inputName].current.click();
  };

  const handleFontFamilyChange = (event) => {
    setSelectedFontFamily(event.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setColor({ ...color, [name]: value });
  };

  const handleAddPage = (section) => {
    const id = uuidv4();
    const editorRef = React.createRef();
    const quillRef = React.createRef();
    const textBox = [];

    const newPage = { id, editorRef, quillRef, textBox };

    if (section === "editor") {
      setEditorPages((prevEditors) => [...prevEditors, newPage]);
    } else if (section === "table") {
      setTablePages((prevPages) => [...prevPages, newPage]);
    }
    setActivePage(newPage);
  };

  useEffect(() => {
    if (activePage) {
      initializeEditors(activePage);
    }
  }, [activePage]);

  const handleRemovePage = (section, id) => {
    
    if (section === "editor") {
      setEditorPages((prevEditors) => {
        if (prevEditors.length > 1) {
          return prevEditors.filter((page) => page.id !== id);
        } else {
          // If only one page left, do not remove it
          return prevEditors;
        }
      });
    } else if (section === "table") {
      setTablePages((prevPages) => prevPages.filter((page) => page.id !== id));
    }
  };

  const addTextBox = (pageId) => {
    const pageExistsInEditorPages = editorPages.some(
      (page) => page.id === pageId
    );
    const pageExistsInTablePages = tablePages.some(
      (page) => page.id === pageId
    );

    let box = {
      id: uuidv4(),
      visible: true,
      content: "",
      textBoxRef: React.createRef(),
      quillRef: React.createRef(),
      position: { x: 0, y: 0 },
      dragging: React.createRef(false),
      offset: React.createRef({ x: 0, y: 0 }),
      isTextareaActive: false,
      resize: "",
      image: false,
      width: "",
      height: "",
      zIndex: 2,
      fontSize: "13",
    };

    if (pageExistsInEditorPages) {
      setEditorPages((prevEditorPages) =>
        prevEditorPages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                textBox: [...page.textBox, box],
              }
            : page
        )
      );

      setActiveBox(box);
    }

    if (pageExistsInTablePages) {
      setTablePages((prevTablePages) =>
        prevTablePages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                textBox: [...page.textBox, box],
              }
            : page
        )
      );

      setActiveBox(box);
    }
  };

  const textBoxImage = (boxId, width, height) => {
    // Helper function to find and update the page containing the boxId
    const findAndUpdateBoxInPages = (pages, setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) => {
          if (page.textBox.some((box) => box.id === boxId)) {
            return {
              ...page,
              textBox: page.textBox.map((box) =>
                box.id === boxId
                  ? { ...box, image: true, width: width, height: height }
                  : box
              ),
            };
          }
          return page;
        })
      );
    };

    // initialPages in editorPages and update if found
    const pageExistsInEditorPages = editorPages.some((page) =>
      page.textBox.some((box) => box.id === boxId)
    );
    if (pageExistsInEditorPages) {
      findAndUpdateBoxInPages(editorPages, setEditorPages);
      return;
    }

    // initialPages in tablePages and update if found
    const pageExistsInTablePages = tablePages.some((page) =>
      page.textBox.some((box) => box.id === boxId)
    );
    if (pageExistsInTablePages) {
      findAndUpdateBoxInPages(tablePages, setTablePages);
    }
  };

  const initializeEditors = (page) => {
    if (page.editorRef.current && !page.quillRef.current) {
      const Font = Quill.import("formats/font");
      Font.whitelist = ["mirza", "roboto", "serif"];
      Quill.register(Font, true);

      const Size = Quill.import("attributors/style/size");
      Size.whitelist = ["10px", "13px", "18px", "24px", "32px", "48px"];
      Quill.register(Size, true);

      const quill = new Quill(page.editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: `#toolbar-${page.id}`,
            handlers: {
              bold: function () {
                const isBold = quill.getSelection() && quill.getFormat().bold;
                quill.format("bold", !isBold);
              },
              italic: function () {
                const isItalic =
                  quill.getSelection() && quill.getFormat().italic;
                quill.format("italic", !isItalic);
              },
              underline: function () {
                const isUnderline =
                  quill.getSelection() && quill.getFormat().underline;
                quill.format("underline", !isUnderline);
              },
              color: function (value) {
                if (quill.getSelection()) {
                  quill.format("color", value);
                  quill.format("background", false);
                }
              },
              background: function (value) {
                if (quill.getSelection()) {
                  quill.format("background", value);
                }
              },
              clean: function () {
                const selection = quill.getSelection();
                if (selection) {
                  quill.removeFormat(selection.index, selection.length);
                }
              },
              list: function (value) {
                const range = quill.getSelection();
                if (range) {
                  const format = quill.getFormat(range);
                  if (format.list === value) {
                    quill.format("list", false);
                  } else {
                    quill.format("list", value);
                  }
                }
              },
              indent: function (value) {
                quill.format("indent", value);
              },
              align: function (value) {
                quill.format("align", value);
              },
              size: function (value) {
                const range = quill.getSelection();
                if (range) {
                  quill.format("size", value);
                }
              },
              font: function (value) {
                const currentSelection = quill.getSelection();
                if (currentSelection) {
                  const { index, length } = currentSelection;
                  quill.formatText(index, length, "font", value);
                  setTimeout(() => {
                    quill.setSelection(index, length);
                  }, 0);
                }
              },
              customTextBox: () =>
                quill.getModule("customTextBox").showTextBox(),
            },
          },
          customTextBox: {
            showTextBox: (range) => {
              const bounds = quill.getBounds(range);
              addTextBox(page.id);
            },
          },
        },
      });

      page.editorRef.current.setAttribute("spellinitialPages", "false");
      page.quillRef.current = quill;
    }
  };

  const initializeTextBox = (box) => {
    if (box && box.textBoxRef.current && !box.quillRef.current) {
      const Font = Quill.import("formats/font");
      Font.whitelist = ["mirza", "roboto", "serif"];
      Quill.register(Font, true);

      const quill = new Quill(box.textBoxRef.current, {
        theme: "snow",
        modules: {
          toolbar: {
            container: `#textBox-${box.id}`,
            handlers: {
              bold: function () {
                const isBold = quill.getSelection() && quill.getFormat().bold;
                quill.format("bold", !isBold);
              },
              italic: function () {
                const isItalic =
                  quill.getSelection() && quill.getFormat().italic;
                quill.format("italic", !isItalic);
              },
              underline: function () {
                const isUnderline =
                  quill.getSelection() && quill.getFormat().underline;
                quill.format("underline", !isUnderline);
              },
              color: function (value) {
                if (box.image) return;
                if (quill.getSelection()) {
                  quill.format("color", value);
                  quill.format("background", false);
                }
              },
              background: function (value) {
                if (box.image) return;
                if (quill.getSelection()) {
                  quill.format("background", value);
                }
              },
              clean: function () {
                const selection = quill.getSelection();
                if (selection) {
                  quill.removeFormat(selection.index, selection.length);
                }
              },
              image: function () {
                const input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");

                input.onchange = async (event) => {
                  const file = event.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const dataUrl = e.target.result;

                      // Create an image element to get the dimensions
                      const img = new Image();
                      img.onload = () => {
                        const width = img.width;
                        const height = img.height;

                        quill.focus();
                        const range = quill.getSelection();
                        quill.setContents([]); // Clear the content
                        quill.insertEmbed(range.index, "image", dataUrl);
                        quill.insertText(range.index + 1, "\u200B"); // Insert zero-width space
                        quill.setSelection(range.index + 1); // Set cursor to the zero-width space

                        if (box.image === false) {
                          textBoxImage(box.id, width, height);
                        }
                      };
                      img.src = dataUrl;
                    };
                    reader.readAsDataURL(file);
                  }
                };
                input.click();
              },
              list: function (value) {
                const range = quill.getSelection();
                if (range) {
                  const format = quill.getFormat(range);
                  if (format.list === value) {
                    quill.format("list", false);
                  } else {
                    quill.format("list", value);
                  }
                }
              },
              align: function (value) {
                quill.format("align", value);
              },
              font: function (value) {
                if (box.image) return;
                const currentSelection = quill.getSelection();
                if (currentSelection) {
                  const { index, length } = currentSelection;
                  quill.formatText(index, length, "font", value);
                  setTimeout(() => {
                    quill.setSelection(index, length);
                  }, 0);
                }
              },
            },
          },
        },
      });

      box.textBoxRef.current.setAttribute("spellinitialPages", "false");
      box.quillRef.current = quill;
    }
  };

  useEffect(() => {
    if (activeBox) {
      initializeTextBox(activeBox);
    }
  }, [activeBox]);

  const handleTextBoxChange = (pageId, boxId) => {
    const updateTextBoxContent = (pages) =>
      pages.map((page) =>
        page.id === pageId
          ? {
              ...page,
              textBox: page.textBox.map((box) => {
                const strippedContent = stripHtmlTags(
                  box.textBoxRef.current.innerHTML
                );
                return box.id === boxId
                  ? {
                      ...box,
                      content: strippedContent === "" ? "" : strippedContent,
                    }
                  : box;
              }),
            }
          : page
      );

    const stripHtmlTags = (htmlString) => {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlString;
      return tempElement.textContent.replace(/\s+/g, " ").trim() || "";
    };

    setEditorPages((prevEditorPages) =>
      prevEditorPages.some((page) => page.id === pageId)
        ? updateTextBoxContent(prevEditorPages)
        : prevEditorPages
    );

    setTablePages((prevTablePages) =>
      prevTablePages.some((page) => page.id === pageId)
        ? updateTextBoxContent(prevTablePages)
        : prevTablePages
    );
  };

  const handleActivePage = (id) => {
    const allPages = editorPages.concat(tablePages);
    const page = allPages.find((page) => page.id === id);
    if (page) {
      setActivePage(page);
      initializeEditors(page);
    }
  };

  const handleDeleteTextBox = (pageId, boxId) => {
    const pageExistsInEditorPages = editorPages.some(
      (page) => page.id === pageId
    );
    const pageExistsInTablePages = tablePages.some(
      (page) => page.id === pageId
    );

    if (pageExistsInEditorPages) {
      setEditorPages((prevEditorPages) =>
        prevEditorPages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                textBox: page.textBox.filter((box) => box.id !== boxId),
              }
            : page
        )
      );
    }

    if (pageExistsInTablePages) {
      setTablePages((prevEditorPages) =>
        prevEditorPages.map((page) =>
          page.id === pageId
            ? {
                ...page,
                textBox: page.textBox.filter((box) => box.id !== boxId),
              }
            : page
        )
      );
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const getPageData = (pages) =>
      pages.map((page) => ({
        id: page.id,
        editorData: page.editorRef.current.innerHTML, // Serialize the content
        quillData: page.quillRef.current.root.innerHTML,
        textbox: page.textBox.map((box) => ({
          id: box.id,
          textBoxData: box.textBoxRef.current.innerHTML, // Serialize the content
          quillData: box.quillRef.current.root.innerHTML,
          position: JSON.stringify(box.position),
          dragging: JSON.stringify(box.dragging.current),
          offset: JSON.stringify(box.offset.current),
          resize: box.resize,
          image: JSON.stringify(box.image),
          width: box.width,
          height: box.height,
          zIndex: JSON.stringify(box.zIndex),
          fontSize: box.fontSize,
        })),
      }));

    const htmlContents = [
      ...getPageData(editorPages),
      ...getPageData(tablePages),
    ];

    try {
      const mergedAreaTables = {
        ...subTotalTables,
        areas: areaTables.map((areaTable) => ({ ...areaTable })),
        background_color: color.background_color,
        table_header: color.table_header,
        table_font: color.table_font,
        table_border: color.table_border,
        table_amount: color.table_amount,
        amount_font: color.amount_font,
        font_family: selectedFontFamily,
      };

      const response = await AXIOS.post("/user-template/save-base-template", {
        template: mergedAreaTables,
        pagesContent: htmlContents,
        pageToMerge: editorPages.length,
      });

      if (response.status === 200) {
        setUpdateSave(true);
        setTimeout(() => {
          setUpdateSave(false);
        }, 3000);
      }
    } catch (error) {
      console.log("Error saving data", error);
    }
  };

  const handleMouseDown = (editorId, boxId, e) => {
    const updateDraggingState = (pages, setPages) => {
      setPages((prevEditors) =>
        prevEditors.map((editor) =>
          editor.id === editorId
            ? {
                ...editor,
                textBox: editor.textBox.map((box) =>
                  box.id === boxId
                    ? {
                        ...box,
                        dragging: { ...box.dragging, current: true },
                        offset: {
                          ...box.offset,
                          current: {
                            x: e.clientX - box.position.x,
                            y: e.clientY - box.position.y,
                          },
                        },
                      }
                    : box
                ),
              }
            : editor
        )
      );
    };

    const pageExistsInEditorPages = editorPages.some(
      (page) => page.id === editorId
    );
    const pageExistsInTablePages = tablePages.some(
      (page) => page.id === editorId
    );

    if (pageExistsInEditorPages) {
      updateDraggingState(editorPages, setEditorPages);
    }

    if (pageExistsInTablePages) {
      updateDraggingState(tablePages, setTablePages);
    }
  };

  const handleMouseMove = (editorId, boxId, e) => {
    const updatePosition = (pages, setPages) => {
      setPages((prevEditors) =>
        prevEditors.map((editor) => {
          if (editor.id === editorId) {
            return {
              ...editor,
              textBox: editor.textBox.map((box) => {
                if (
                  box.id === boxId &&
                  box.dragging.current &&
                  box.textBoxRef.current
                ) {
                  const newX = e.clientX - box.offset.current.x;
                  const newY = e.clientY - box.offset.current.y;

                  const containerWidth =
                    editor.editorRef.current?.offsetWidth + 60 || 0;
                  const containerHeight =
                    editor.editorRef.current?.offsetHeight + 40 || 0;
                  console.log(containerHeight);
                  const elementWidth = box.textBoxRef.current?.offsetWidth || 0;
                  const elementHeight =
                    box.textBoxRef.current?.offsetHeight || 0;

                  const clampedX = Math.max(
                    0,
                    Math.min(newX, containerWidth - elementWidth)
                  );
                  const clampedY = Math.max(
                    0,
                    Math.min(newY, containerHeight - elementHeight)
                  );

                  return {
                    ...box,
                    position: { x: clampedX, y: clampedY },
                  };
                }
                return box;
              }),
            };
          }
          return editor;
        })
      );
    };

    const pageExistsInEditorPages = editorPages.some(
      (page) => page.id === editorId
    );
    const pageExistsInTablePages = tablePages.some(
      (page) => page.id === editorId
    );

    if (pageExistsInEditorPages) {
      updatePosition(editorPages, setEditorPages);
    }

    if (pageExistsInTablePages) {
      updatePosition(tablePages, setTablePages);
    }
  };

  const handleResize = (editorId, boxId) => {
    const updateResize = (pages, setPages) => {
      setPages((prevEditors) =>
        prevEditors.map((editor) => {
          if (editor.id === editorId) {
            return {
              ...editor,
              textBox: editor.textBox.map((box) => {
                if (box.id === boxId) {
                  const containerWidth =
                    editor.editorRef.current?.offsetWidth + 60 || 0;
                  const containerHeight =
                    editor.editorRef.current?.offsetHeight + 40 || 0;
                  const elementWidth = box.textBoxRef.current?.offsetWidth || 0;
                  const elementHeight =
                    box.textBoxRef.current?.offsetHeight || 0;

                  const isWithinBounds =
                    box.position.x + elementWidth <= containerWidth &&
                    box.position.y + elementHeight <= containerHeight &&
                    box.isTextareaActive;

                  // Update box width and height based on element dimensions
                  const newWidth = `${elementWidth}px`;
                  const newHeight = `${elementHeight}px`;

                  return {
                    ...box,
                    resize: isWithinBounds ? "both" : "none",
                    width: newWidth,
                    height: newHeight,
                  };
                }
                return box;
              }),
            };
          }
          return editor;
        })
      );
    };

    const pageExistsInEditorPages = editorPages.some(
      (page) => page.id === editorId
    );
    const pageExistsInTablePages = tablePages.some(
      (page) => page.id === editorId
    );

    if (pageExistsInEditorPages) {
      updateResize(editorPages, setEditorPages);
    }

    if (pageExistsInTablePages) {
      updateResize(tablePages, setTablePages);
    }
  };

  const handleImageZIndex = (type, editorId, boxId, operation) => {
    const updateZIndex = (pages, setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) => {
          if (page.id === editorId) {
            return {
              ...page,
              textBox: page.textBox.map((box) => {
                if (box.id === boxId) {
                  const currentZIndex = box.zIndex || 1;
                  let newZIndex =
                    operation === "plus"
                      ? currentZIndex + 1
                      : currentZIndex - 1;
                  newZIndex = newZIndex < 1 ? 1 : newZIndex; // Ensure zIndex does not go below 10
                  return {
                    ...box,
                    zIndex: newZIndex,
                  };
                }
                return box;
              }),
            };
          }
          return page;
        })
      );
    };

    if (type === "editor") {
      updateZIndex(editorPages, setEditorPages);
    } else if (type === "table") {
      updateZIndex(tablePages, setTablePages);
    }
  };

  const handleFontsize = (type, editorId, boxId, operation) => {
    const updateFontSize = (pages, setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) => {
          if (page.id === editorId) {
            return {
              ...page,
              textBox: page.textBox.map((box) => {
                if (box.id === boxId) {
                  const currentFontSize = parseFloat(box.fontSize) || 10;
                  let newFontSize =
                    operation === "plus"
                      ? currentFontSize + 1
                      : currentFontSize - 1;
                  newFontSize = newFontSize < 10 ? 10 : newFontSize; // Ensure zIndex does not go below 10
                  return {
                    ...box,
                    fontSize: newFontSize,
                  };
                }
                return box;
              }),
            };
          }
          return page;
        })
      );
    };

    if (type === "editor") {
      updateFontSize(editorPages, setEditorPages);
    } else if (type === "table") {
      updateFontSize(tablePages, setTablePages);
    }
  };

  const handleFontSizeInput = (type, editorId, boxId, newFontSize) => {
    const updateFontSize = (pages, setPages) => {
      setPages((prevPages) =>
        prevPages.map((page) => {
          if (page.id === editorId) {
            return {
              ...page,
              textBox: page.textBox.map((box) => {
                if (box.id === boxId) {
                  // Parse the new font size as float or fallback to default 10
                  const parsedFontSize = newFontSize
                    ? parseFloat(newFontSize)
                    : "";
                  return {
                    ...box,
                    fontSize: parsedFontSize.toString(), // Ensure it's stored as string if needed
                  };
                }
                return box;
              }),
            };
          }
          return page;
        })
      );
    };

    if (type === "editor") {
      updateFontSize(editorPages, setEditorPages);
    } else if (type === "table") {
      updateFontSize(tablePages, setTablePages);
    }
  };

  useEffect(() => {
    const handleGlobalMouseUp = () => {
      setEditorPages((prevEditors) =>
        prevEditors.map((editor) => ({
          ...editor,
          textBox: editor.textBox.map((box) =>
            box.dragging.current
              ? { ...box, dragging: { ...box.dragging, current: false } }
              : box
          ),
        }))
      );

      setTablePages((prevEditors) =>
        prevEditors.map((editor) => ({
          ...editor,
          textBox: editor.textBox.map((box) =>
            box.dragging.current
              ? { ...box, dragging: { ...box.dragging, current: false } }
              : box
          ),
        }))
      );
    };

    document.addEventListener("mouseup", handleGlobalMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleGlobalMouseUp);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const updateState = (pages, setPages, editorId) => {
        setPages((prevPages) =>
          prevPages.map((page) => {
            if (page.id === editorId) {
              return {
                ...page,
                textBox: page.textBox.map((box) => {
                  if (box.isTextareaActive && box.textBoxRef.current) {
                    const toolsContainer = document.getElementById(
                      `textBox-${box.id}`
                    );
                    if (
                      !box.textBoxRef.current.contains(event.target) &&
                      (!toolsContainer ||
                        !toolsContainer.contains(event.target))
                    ) {
                      return { ...box, isTextareaActive: false };
                    }
                  }
                  return box;
                }),
              };
            }
            return page;
          })
        );
      };

      editorPages.forEach((editorPage) => {
        updateState(editorPages, setEditorPages, editorPage.id);
      });

      tablePages.forEach((tablePage) => {
        updateState(tablePages, setTablePages, tablePage.id);
      });
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editorPages, setEditorPages, tablePages, setTablePages]);

  const initializeTemplateData = (
    templateData,
    setSubTotalTables,
    setAreaTables,
    setEditorPages,
    setTablePages,
    setActivePage,
    initializeEditors,
    setActiveBox,
    initializeTextBox,
    setInitialPages,
    setInitialBoxes,
    setPageContentData,
    setColor,
    setLoading
  ) => {
    const newSubTotalTables = {
      subTotal: templateData.subTotal || "0",
      total: templateData.total || "0",
      taxableAmount: templateData.taxableAmount || "0",
      gst: templateData.gst || {
        name: "",
        percentage: "",
        amount: "0",
      },
      add: templateData.add || [
        { id: uuidv4(), name: "", percentage: "", amount: "0" },
      ],
      minus: templateData.minus || [
        { id: uuidv4(), name: "", percentage: "", amount: "0" },
      ],
    };

    // Extract areaTables data from templateData
    const newAreaTables = (templateData.template || []).map((table) => ({
      id: table.id || uuidv4(),
      area: (table.areas || []).map((area) => ({
        id: area.area_id || uuidv4(),
        areaValue: area.name || "",
        amount: area.amount || "",
        items: (area.items || []).map((item) => ({
          id: item.item_id || uuidv4(),
          itemValue: item.name || "",
          descriptionValue: item.description || "",
          amount: item.amount || "",
          subItems: (item.subItems || []).map((subItem) => ({
            id: subItem.name_id || uuidv4(),
            subItemValue: subItem.name || "",
            amount: subItem.amount || "",
            category: subItem.category || "",
            finish: subItem.finish || "",
            type: subItem.type || "",
            height: subItem.height || "",
            width: subItem.width || "",
            amount: subItem.amount || "",
            unit: subItem.unit || "",
            range: subItem.range || "",
            rate: subItem.rate || "",
            description:subItem.description || "",
          })),
        })),
      })),
    }));

    if (templateData.pagesContent.length > 0) {
      const pagesContent = templateData.pagesContent || [];
      const pageToMerge = parseInt(templateData.pageToMerge, 10);

      const stripHtmlTags = (htmlString) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = htmlString;
        return tempElement.textContent.replace(/\s+/g, " ").trim() || "";
      };

      let newEditorPages = pagesContent.slice(0, pageToMerge).map((page) => ({
        id: page.id,
        editorRef: React.createRef(),
        quillRef: React.createRef(),
        textBox: page.textbox.map((box) => ({
          id: box.id,
          textBoxRef: React.createRef(),
          quillRef: React.createRef(),
          position: JSON.parse(box.position),
          dragging: { current: JSON.parse(box.dragging) },
          offset: { current: JSON.parse(box.offset) },
          resize: box.resize,
          image: JSON.parse(box.image),
          width: box.width,
          height: box.height,
          zIndex: JSON.parse(box.zIndex),
          fontSize: box.fontSize,
          content: stripHtmlTags(box.quillData),
          isTextareaActive: false,
        })),
      }));

      let newTablePages = pagesContent.slice(pageToMerge).map((page) => ({
        id: page.id,
        editorRef: React.createRef(),
        quillRef: React.createRef(),
        textBox: page.textbox.map((box) => ({
          id: box.id,
          textBoxRef: React.createRef(),
          quillRef: React.createRef(),
          position: JSON.parse(box.position),
          dragging: { current: JSON.parse(box.dragging) },
          offset: { current: JSON.parse(box.offset) },
          resize: box.resize,
          image: JSON.parse(box.image),
          width: box.width,
          height: box.height,
          zIndex: JSON.parse(box.zIndex),
          fontSize: box.fontSize,
          content: stripHtmlTags(box.quillData),
          isTextareaActive: false,
        })),
      }));

      if (newEditorPages.length > 0) {
        setEditorPages(newEditorPages);
        setActivePage(newEditorPages[0]);
        initializeEditors(newEditorPages[0]);
        setActiveBox(newEditorPages[0].textBox[0]);
        initializeTextBox(newEditorPages[0].textBox[0]);
      }

      if (newTablePages.length > 0) {
        setTablePages(newTablePages);
        setActivePage(newTablePages[0]);
        initializeEditors(newTablePages[0]);
        setActiveBox(newTablePages[0].textBox[0]);
        initializeTextBox(newTablePages[0].textBox[0]);
      }
      setInitialPages(newEditorPages.concat(newTablePages));

      let boxes = [];
      newEditorPages.concat(newTablePages).forEach((page) => {
        page.textBox.forEach((box) => {
          boxes.push(box);
        });
      });

      setInitialBoxes(boxes);

      setPageContentData((prev) => ({
        ...prev,
        content: pagesContent,
        pageMerge: pageToMerge,
      }));
    }

    setSubTotalTables(newSubTotalTables);
    setAreaTables(newAreaTables);
    setColor({
      background_color: templateData.background_color || "#fff7ef",
      table_header: templateData.table_header || "#f16f40",
      table_font: templateData.table_font || "#ffffff",
      table_border: templateData.table_border || "#f16f40",
      table_amount: templateData.table_amount || "#ffffff",
      amount_font: templateData.amount_font || "#636363",
    });

    if (templateData.pagesContent.length === 0) {
      setActivePage(editorPages[0]);
      initializeEditors(editorPages[0]);
    }

    setLoading(false);
  };

  const handleAdminTemplate = async (id) => {
    try {
      const response = await AXIOS.get(`/admin/get-admin-template/${id}`);
      if (response.status === 200) {
        const templateData = response.data.Templates[0];

        if (templateData) {
          initializeTemplateData(
            templateData,
            setSubTotalTables,
            setAreaTables,
            setEditorPages,
            setTablePages,
            setActivePage,
            initializeEditors,
            setActiveBox,
            initializeTextBox,
            setInitialPages,
            setInitialBoxes,
            setPageContentData,
            setColor,
            setLoading
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (loading) {
    return <Loading />;
  }

  if (isMobile) {
    return <MobileScreeMessage />;
  }

  return (
    <div>
      {isMedia && <PageEditorMedia setIsMedia={setIsMedia} />}

      <figure>
        <img
          src={landingPageImg}
          alt="Cover img"
          className="partner-cover-img"
        />
      </figure>

      <section
        className={`quotation-template ${isLandscape ? "landscape" : ""}`}
      >
        <article
          className={`quotation-template-container ${
            isScreenSmall
              ? !isSidebarOpen
                ? "position-adjustment-container"
                : ""
              : ""
          }`}
        >
          {/* Pdf text entering space  */}
          <section className="editor-section">
            {editorPages.map((editor) => (
              <div
                key={editor.id}
                className="pdf-text-editor"
                onClick={() => handleActivePage(editor.id)}
                spellCheck={false}
              >
                <div
                  className="pdf-text-editor-space"
                  style={{ backgroundColor: color.background_color }}
                >
                  <div className="ql-container ql-snow">
                    {editor.textBox &&
                      editor.textBox.map((box) => (
                        <div
                          className="draggable-container ql-container ql-snow"
                          onMouseMove={(e) =>
                            handleMouseMove(editor.id, box.id, e)
                          }
                          key={box.id}
                          style={{ height: "auto" }}
                          onClick={() => setActiveBox(box)}
                        >
                          <div
                            className="drag-handle"
                            onMouseDown={(e) =>
                              handleMouseDown(editor.id, box.id, e)
                            }
                            style={{
                              left: box.position.x,
                              top: box.position.y,
                              opacity: ` ${box.isTextareaActive ? "1" : "0"}`,
                              zIndex: `${box.zIndex ? box.zIndex + 1 : 2}`,
                            }}
                          >
                            <span
                              className="drag-icon"
                              style={{
                                marginLeft: parseFloat(box.width) * 0.5,
                              }}
                            >
                              <RiDragMoveFill />
                            </span>
                          </div>
                          <div
                            style={{
                              left: box.position.x,
                              top: box.position.y,
                              width: box.width ? box.width : "",
                              height: box.height ? box.height : "",
                              resize: box.resize || "none",
                              border:
                                box.content &&
                                box.isTextareaActive &&
                                !box.image
                                  ? "1.5px solid rgb(222, 213, 213)"
                                  : box.content.trim().length <= 1 &&
                                    !box.image &&
                                    "1.5px solid rgb(222, 213, 213)",
                              padding: "0px",
                              zIndex: `${box.zIndex ? box.zIndex : 1}`,
                              fontSize: box.fontSize
                                ? `${box.fontSize}px`
                                : "13px",
                            }}
                            ref={box.textBoxRef}
                            className={`pdf-text-box ${
                              !box.image && "ql-editor ql-blank"
                            }`}
                            spellinitialPages={false}
                            onMouseMove={(e) => {
                              handleMouseMove(editor.id, box.id, e);
                              handleResize(editor.id, box.id);
                            }}
                            onMouseUp={() => handleResize(editor.id, box.id)}
                            onFocus={() =>
                              setEditorPages((prevEditors) =>
                                prevEditors.map((ed) =>
                                  ed.id === editor.id
                                    ? {
                                        ...ed,
                                        textBox: ed.textBox.map((tb) =>
                                          tb.id === box.id
                                            ? { ...tb, isTextareaActive: true }
                                            : tb
                                        ),
                                      }
                                    : ed
                                )
                              )
                            }
                            onInput={() =>
                              handleTextBoxChange(editor.id, box.id)
                            }
                          ></div>
                          <div
                            id={`textBox-${box.id}`}
                            className="text-box-tools-container"
                            style={{
                              left: box.position.x,
                              top: box.position.y,
                              opacity: ` ${box.isTextareaActive ? "1" : "0"}`,
                              marginLeft: box.width,
                              backgroundColor: color.background_color,
                              zIndex: `${box.zIndex ? box.zIndex : 1}`,
                              border: "1.5px solid rgb(222, 213, 213)",
                              borderRadius: "0 10px 10px 0px",
                            }}
                          >
                            <div>
                              {!box.image && (
                                <div>
                                  <button className="ql-bold"></button>

                                  <button className="ql-italic"></button>

                                  <button className="ql-underline"></button>
                                  <select className="ql-color"></select>

                                  <select className="ql-background"></select>

                                  <button
                                    className="ql-list"
                                    value="ordered"
                                  ></button>

                                  <button
                                    className="ql-list"
                                    value="bullet"
                                  ></button>

                                  <button
                                    className="ql-align"
                                    value=""
                                  ></button>

                                  <button
                                    className="ql-align"
                                    value="center"
                                  ></button>

                                  <button
                                    className="ql-align"
                                    value="right"
                                  ></button>

                                  <button className="ql-clean"></button>
                                  <button className="ql-image"></button>
                                  <MdDelete
                                    className="textbox-delete-icon"
                                    onClick={() =>
                                      handleDeleteTextBox(editor.id, box.id)
                                    }
                                  />

                                  <div className="text-box-font-size">
                                    <HiMinus
                                      className="font-size-icon"
                                      onClick={() =>
                                        handleFontsize(
                                          "editor",
                                          editor.id,
                                          box.id,
                                          "minus"
                                        )
                                      }
                                    />
                                    <input
                                      type="text"
                                      value={box.fontSize}
                                      placeholder="0"
                                      onInput={(e) =>
                                        handleFontSizeInput(
                                          "editor",
                                          editor.id,
                                          box.id,
                                          e.target.value.replace(/[^\d]/g, "")
                                        )
                                      }
                                    />

                                    <FaPlus
                                      className="font-size-icon"
                                      onClick={() =>
                                        handleFontsize(
                                          "editor",
                                          editor.id,
                                          box.id,
                                          "plus"
                                        )
                                      }
                                    />
                                  </div>

                                  <select className="ql-font">
                                    <option value="mirza">Mirza</option>
                                    <option value="roboto">Roboto</option>
                                    <option value="serif">Serif</option>
                                  </select>
                                </div>
                              )}
                              {box.image && (
                                <div>
                                  <FaPlus
                                    className="image-z-index-icon"
                                    onClick={() =>
                                      handleImageZIndex(
                                        "editor",
                                        editor.id,
                                        box.id,
                                        "plus"
                                      )
                                    }
                                  />
                                  <HiMinus
                                    className="image-z-index-icon"
                                    onClick={() =>
                                      handleImageZIndex(
                                        "editor",
                                        editor.id,
                                        box.id,
                                        "minus"
                                      )
                                    }
                                  />
                                   <MdDelete
                                    className="image-z-index-icon image-textbox-delete-icon"
                                    onClick={() =>
                                      handleDeleteTextBox(editor.id, box.id)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    <div
                      ref={editor.editorRef}
                      className="pdf-text-enter-space ql-editor ql-blank"
                    ></div>
                  </div>
                </div>
                <HiOutlineMinusSm
                  className="page-minus-icon"
                  onClick={() => handleRemovePage("editor", editor.id)}
                />
              </div>
            ))}

            <div className="add-page-container">
              <button
                className="add-page-button"
                onClick={() => handleAddPage("editor")}
              >
                Add Page
              </button>
            </div>
          </section>

          {/* quotation table  */}
          <section className="quotation-table">
            <QuotationTable
              areaTables={areaTables}
              setAreaTables={setAreaTables}
              rateTables={rateTables}
              setRateTables={setRateTables}
              subTotalTables={subTotalTables}
              setSubTotalTables={setSubTotalTables}
              setFinishes={setFinishes}
              finishes={finishes}
              background_color={color.background_color}
              table_header={color.table_header}
              table_font={color.table_font}
              table_border={color.table_border}
              table_amount={color.table_amount}
              amount_font={color.amount_font}
              selectedFontFamily={selectedFontFamily}
              isScreenSmall={isScreenSmall}
              isSidebarOpen={isSidebarOpen}
            />
          </section>

          {tablePages.map((page) => (
            <div
              key={page.id}
              className="pdf-text-editor"
              onClick={() => handleActivePage(page.id)}
              spellCheck={false}
            >
              <div
                className="pdf-text-editor-space"
                style={{ backgroundColor: color.background_color }}
              >
                <div className="ql-container ql-snow">
                  {page.textBox &&
                    page.textBox.map((box) => (
                      <div
                        className="draggable-container ql-container ql-snow"
                        onMouseMove={(e) => handleMouseMove(page.id, box.id, e)}
                        key={box.id}
                        style={{ height: "auto" }}
                        onClick={() => setActiveBox(box)}
                      >
                        <div
                          className="drag-handle"
                          onMouseDown={(e) =>
                            handleMouseDown(page.id, box.id, e)
                          }
                          style={{
                            left: box.position.x,
                            top: box.position.y,
                            opacity: ` ${box.isTextareaActive ? "1" : "0"}`,
                            zIndex: `${box.zIndex ? box.zIndex + 1 : 2}`,
                          }}
                        >
                          <span
                            className="drag-icon"
                            style={{ marginLeft: parseFloat(box.width) * 0.5 }}
                          >
                            <RiDragMoveFill />
                          </span>
                        </div>
                        <div
                          style={{
                            left: box.position.x,
                            top: box.position.y,
                            width: box.width ? box.width : "",
                            height: box.height ? box.height : "",
                            resize: box.resize || "none",
                            border:
                              box.content && box.isTextareaActive && !box.image
                                ? "1.5px solid rgb(222, 213, 213)"
                                : box.content.trim().length <= 1 &&
                                  !box.image &&
                                  "1.5px solid rgb(222, 213, 213)",
                            padding: "0px",
                            zIndex: `${box.zIndex ? box.zIndex : 1}`,
                            fontSize: box.fontSize
                              ? `${box.fontSize}px`
                              : "13px",
                          }}
                          ref={box.textBoxRef}
                          className={`pdf-text-box ${
                            !box.image && "ql-editor ql-blank"
                          }`}
                         
                          spellinitialPages={false}
                          onMouseMove={(e) => {
                            handleMouseMove(page.id, box.id, e);
                            handleResize(page.id, box.id);
                          }}
                          onMouseUp={() => handleResize(page.id, box.id)}
                          onFocus={() =>
                            setTablePages((prevEditors) =>
                              prevEditors.map((ed) =>
                                ed.id === page.id
                                  ? {
                                      ...ed,
                                      textBox: ed.textBox.map((tb) =>
                                        tb.id === box.id
                                          ? { ...tb, isTextareaActive: true }
                                          : tb
                                      ),
                                    }
                                  : ed
                              )
                            )
                          }
                          onInput={() => handleTextBoxChange(page.id, box.id)}
                        ></div>
                        <div
                          id={`textBox-${box.id}`}
                          className="text-box-tools-container"
                          style={{
                            left: box.position.x,
                            top: box.position.y,
                            opacity: ` ${box.isTextareaActive ? "1" : "0"}`,
                            marginLeft: box.width,
                            backgroundColor: color.background_color,
                            zIndex: `${box.zIndex ? box.zIndex : 1}`,
                            border: "1.5px solid rgb(222, 213, 213)",
                            borderRadius: "0 10px 10px 0px",
                          }}
                        >
                          <div>
                            {!box.image && (
                              <div>
                                <button className="ql-bold"></button>

                                <button className="ql-italic"></button>

                                <button className="ql-underline"></button>
                                <select className="ql-color"></select>

                                <select className="ql-background"></select>

                                <button
                                  className="ql-list"
                                  value="ordered"
                                ></button>

                                <button
                                  className="ql-list"
                                  value="bullet"
                                ></button>

                                <button className="ql-align" value=""></button>

                                <button
                                  className="ql-align"
                                  value="center"
                                ></button>

                                <button
                                  className="ql-align"
                                  value="right"
                                ></button>

                                <button className="ql-clean"></button>
                                <button className="ql-image"></button>
                                <MdDelete
                                    className="textbox-delete-icon"
                                    onClick={() =>
                                      handleDeleteTextBox(page.id, box.id)
                                    }
                                  />
                                <div className="text-box-font-size">
                                  <HiMinus
                                    className="font-size-icon"
                                    onClick={() =>
                                      handleFontsize(
                                        "table",
                                        page.id,
                                        box.id,
                                        "minus"
                                      )
                                    }
                                  />
                                  <input
                                    type="text"
                                    value={box.fontSize}
                                    placeholder="0"
                                    onInput={(e) =>
                                      handleFontSizeInput(
                                        "table",
                                        page.id,
                                        box.id,
                                        e.target.value.replace(/[^\d]/g, "")
                                      )
                                    }
                                  />
                                  <FaPlus
                                    className="font-size-icon"
                                    onClick={() =>
                                      handleFontsize(
                                        "table",
                                        page.id,
                                        box.id,
                                        "plus"
                                      )
                                    }
                                  />
                                </div>

                                <select className="ql-font">
                                  <option value="mirza">Mirza</option>
                                  <option value="roboto">Roboto</option>
                                  <option value="serif">Serif</option>
                                </select>
                              </div>
                            )}
                            {box.image && (
                              <div>
                                <FaPlus
                                  className="image-z-index-icon"
                                  onClick={() =>
                                    handleImageZIndex(
                                      "table",
                                      page.id,
                                      box.id,
                                      "plus"
                                    )
                                  }
                                />
                                <HiMinus
                                  className="image-z-index-icon"
                                  onClick={() =>
                                    handleImageZIndex(
                                      "table",
                                      page.id,
                                      box.id,
                                      "minus"
                                    )
                                  }
                                />
                                <MdDelete
                                    className="image-z-index-icon image-textbox-delete-icon"
                                    onClick={() =>
                                      handleDeleteTextBox(page.id, box.id)
                                    }
                                  />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  <div
                    ref={page.editorRef}
                    className="pdf-text-enter-space ql-editor ql-blank"
                    spellCheck={false}
                  ></div>
                </div>
              </div>
              <HiOutlineMinusSm
                className="page-minus-icon"
                onClick={() => handleRemovePage("table", page.id)}
              />
            </div>
          ))}

          <div className="add-page-container quotation-add-page-container">
            <button
              className="add-page-button"
              onClick={() => handleAddPage("table")}
            >
              Add Page
            </button>
          </div>
        </article>

        {/* side bar section  */}
        <aside
          className={`pdf-editor-side-bar-container ${
            isScreenSmall
              ? isSidebarOpen
                ? "sidebar-open"
                : "sidebar-closed"
              : ""
          }`}
          style={{
            transform: isScreenSmall
              ? isSidebarOpen
                ? "translateX(0)"
                : "translateX(94%)"
              : "translateX(0%)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <div className="pdf-editor-side-bar">
            <div className="sidebar-toggle-button" onClick={toggleSidebar}>
              {isSidebarOpen ? <FaArrowRightLong /> : <FaArrowLeftLong />}
            </div>
            <div className="pdf-template-editor-section">
              <p
                className="pdf-editor-finishes-rates"
                onClick={() => {
                  setFinishes(true);
                }}
              >
                Finishes and Rates
              </p>
              <div className="pdf-editor-template-section">
                <div className="pdf-editor-template-header">
                  <p className="pdf-editor-template-title">Template</p>
                  <IoMdRefresh
                    className="pdf-editor-refresh-icon"
                    onClick={tableData}
                  />
                </div>
                <nav className="pdf-editor-template-list">
                  <p
                    className="pdf-editor-template-item"
                    onClick={() => handleAdminTemplate("template01")}
                  >
                    Template 01
                  </p>
                  <p
                    className="pdf-editor-template-item"
                    onClick={() => handleAdminTemplate("template02")}
                  >
                    Template 02
                  </p>
                  <p
                    className="pdf-editor-template-item"
                    onClick={() => handleAdminTemplate("template03")}
                  >
                    Template 03
                  </p>
                  <p
                    className="pdf-editor-template-item"
                    onClick={() => handleAdminTemplate("template04")}
                  >
                    Template 04
                  </p>
                </nav>
              </div>
            </div>

            <div className="pdf-editor-sidebar-section">
              {/* Text header section */}
              {editorPages.concat(tablePages).map((page) => (
                <div
                  className="pdf-editor-text-header"
                  key={page.id}
                  style={{
                    display: activePage.id === page.id ? "block" : "none",
                  }}
                >
                  <p className="pdf-editor-text-title">Page Editor</p>
                  <div id={`toolbar-${page.id}`}>
                    <div className="color-picker-section">
                      <div className="color-picker-container">
                        <div
                          className="color-picker-icon-section"
                          onClick={() => handleDivClick("background_color")}
                        >
                          <BiSolidColorFill className="color-picker-icon" />
                          <input
                            name="background_color"
                            type="color"
                            value={color.background_color}
                            onChange={handleChange}
                            id="background_color"
                            ref={inputRefs.background_color}
                          />
                        </div>
                        <p className="color-picker-text">Page Background</p>
                      </div>
                    </div>
                    <button className="ql-bold"></button>
                    <button className="ql-italic"></button>
                    <button className="ql-underline"></button>
                    <select className="ql-font" defaultValue="Aref Ruqaa">
                      <option value="Aref Ruqaa">Aref Ruqaa</option>
                      <option value="mirza">Mirza</option>
                      <option value="roboto">Roboto</option>
                      <option value="serif">Serif</option>
                    </select>

                    <select className="ql-color"></select>
                    <select className="ql-background"></select>
                    <button className="ql-indent" value="-1"></button>
                    <button className="ql-indent" value="+1"></button>
                    <button className="ql-list" value="ordered"></button>
                    <button className="ql-list" value="bullet"></button>
                    <button className="ql-align" value=""></button>
                    <button className="ql-align" value="center"></button>
                    <button className="ql-align" value="right"></button>
                    <button className="ql-clean"></button>
                    <button className="ql-customTextBox">
                      <ImTextWidth />
                    </button>
                    <select
                      title="Size"
                      className="ql-size"
                      style={{ width: "45px" }}
                    >
                      <option value="10px">10</option>
                      <option value="13px">13</option>
                      <option value="18px">18</option>
                      <option value="24px">24</option>
                      <option value="32px">32</option>
                      <option value="48px">48</option>
                    </select>
                  </div>
                </div>
              ))}

              {/* Quotation header section */}
              <div className="pdf-editor-customize-header">
                <p className="pdf-editor-customize-title">Quotation Editor</p>

                <div className="quotation-editor-tools">
                  {/* table header and font color change */}
                  <div className="table-color-picker-section">
                    <div className="table-font-family-section">
                      <select
                        id="font-family"
                        value={selectedFontFamily}
                        onChange={handleFontFamilyChange}
                      >
                        <option value="" disabled>
                          Select font family
                        </option>
                        {fontFamilies.map((fontFamily, index) => (
                          <option key={index} value={fontFamily}>
                            {fontFamily}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="color-picker-container">
                      <div
                        className="color-picker-icon-section"
                        onClick={() => handleDivClick("table_header")}
                      >
                        <RiFontFamily className="color-picker-icon" />
                        <input
                          name="table_header"
                          type="color"
                          value={color.table_header}
                          onChange={handleChange}
                          id="table_header"
                          ref={inputRefs.table_header}
                        />
                      </div>
                      <p className="color-picker-text">Header</p>
                    </div>
                  </div>

                  {/* table Border and background color change */}
                  <div className="table-color-picker-section">
                    <div className="color-picker-container">
                      <div
                        className="color-picker-icon-section"
                        onClick={() => handleDivClick("table_font")}
                      >
                        <RiFontFamily className="color-picker-icon" />
                        <input
                          name="table_font"
                          type="color"
                          value={color.table_font}
                          onChange={handleChange}
                          id="table_font"
                          ref={inputRefs.table_font}
                        />
                      </div>
                      <p className="color-picker-text">Font</p>
                    </div>
                    <div className="color-picker-container">
                      <div
                        className="color-picker-icon-section"
                        onClick={() => handleDivClick("table_border")}
                      >
                        <BiSolidColorFill className="color-picker-icon" />
                        <input
                          name="table_border"
                          type="color"
                          value={color.table_border}
                          onChange={handleChange}
                          id="table_border"
                          ref={inputRefs.table_border}
                        />
                      </div>
                      <p className="color-picker-text">Border</p>
                    </div>
                    <div className="color-picker-container">
                      <div
                        className="color-picker-icon-section"
                        onClick={() => handleDivClick("table_amount")}
                      >
                        <BiSolidColorFill className="color-picker-icon" />
                        <input
                          name="table_amount"
                          type="color"
                          value={color.table_amount}
                          onChange={handleChange}
                          id="table_amount"
                          ref={inputRefs.table_amount}
                        />
                      </div>
                      <p className="color-picker-text">Amount</p>
                    </div>
                  </div>

                  <div className="table-color-picker-section">
                    <div className="color-picker-container">
                      <div
                        className="color-picker-icon-section"
                        onClick={() => handleDivClick("amount_font")}
                      >
                        <RiFontFamily className="color-picker-icon" />
                        <input
                          name="amount_font"
                          type="color"
                          value={color.amount_font}
                          onChange={handleChange}
                          id="amount_font"
                          ref={inputRefs.amount_font}
                        />
                      </div>
                      <p className="color-picker-text">Amount Font</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* save button  */}
          <div className="pdf-editor-side-bar-button">
            {!updateSave && <button onClick={handleSave}>Save</button>}
            {updateSave && <p>Saved Successfully</p>}
          </div>
        </aside>
      </section>
    </div>
  );
}

export default QuotationTemplate;
